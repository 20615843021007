.resend-message-component {
  background: #4466EE;
  padding: 8px 44px;
  align-self: flex-start;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 16px 24px;
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1216px;
    margin: 0px auto;
  }

  &-title,
  &-btn {
    font-family: "Inter-semibold";
    color: #ffffff;
    font-size: 12px;
    line-height: 136%;
  }

  @media screen and (max-width: 800px) {
    &-title {
      padding-right: 24px;
    }
  }

  &-btn {
    border: 2px solid #FFFFFF;
    border-radius: 99px;
    padding: 13.9px 42.1px;
    background: transparent;
    cursor: pointer;
    transition: 0.5s;

    @media screen and (max-width: 800px) {
      padding: 6px 22px;
      width: 152px;
      flex: 0 0 152px;
    }

    &-disabled {
      pointer-events: none;
    }

    &:hover {
      transition: 0.5s;
      background: #FFFFFF;
      color: #4466EE;
    }
  }
}
