.successfull-message-component {
  padding: 24px 44px;
  color: #ffffff;
  background: #282828;
  align-self: flex-start;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 800px) {
    padding: 16px 24px;
  }

  &.seven-sec-animation {
    animation: show-hide 7s linear;
  }

  &.nine-sec-animation {
    animation: show-hide 9s linear;
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1216px;
    margin: 0px auto;

    .successfull-message-component-title {
      font-size: 12px;
      line-height: 136%;
      font-family: "Inter-semibold";
      overflow-wrap: break-word;
      width: calc(100% - 16px);
      padding-right: 24px;
    }

    p:first-letter {
      text-transform: capitalize;
    }
  }

  &-hide {
    cursor: pointer;
    width: 16px;
    height: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 800px) {
      width: 24px;
      height: 24px;
    }

    img {
      width: 9.33px;
      height: 9.33px;

      @media screen and (max-width: 800px) {
        width: 14px;
        height: 14px;
      }
    }
  }

  @keyframes show-hide {
    0% {
      display: block;
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
}

.hide {
  display: none;
}
