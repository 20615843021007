.loader-component.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  &.w-100 {
    width: 100%;
  }

  &.h-100 {
    height: 100%;
  }

  .loader-component-icon {
    width: 72px;
    height: 72px;
    animation: spin 0.8s linear infinite;
    background-image: url(../../images/loader.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text {
    margin-top: 8px;
    font-family: "Inter-semibold";
    font-size: 16px;
    line-height: 150%;

    @media screen and (max-width: 800px) {
      font-size: 12px;
      line-height: 136%;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
