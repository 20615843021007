* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Inter-regular", sans-serif;
  background-color: #e5e5e5;
  font-size: 16px;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.container {
  max-width: 1200px;
}

ul {
  list-style-type: none;
}

@font-face {
  font-family: "Inter-regular";
  src: url(./fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter-semibold";
  src: url(./fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: "Inter-bold";
  src: url(./fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: "Inter-extrabold";
  src: url(./fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: "Inter-black";
  src: url(./fonts/Inter-Black.ttf);
}

.unselectable {
  user-select: none;
}
