.error-message-component {
  padding: 24px 44px;
  color: #ffffff;
  background: #FF3344;
  align-self: flex-start;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 800px) {
    padding: 16px 24px;
  }

  &.seven-sec-animation {
    animation: show-hide 7s linear;
  }

  &.nine-sec-animation {
    animation: show-hide 9s linear;
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1216px;
    margin: 0px auto;

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 16px);

      .text {
        font-size: 12px;
        line-height: 136%;
        font-family: "Inter-semibold";
        overflow-wrap: break-word;
        width: calc(100% - 30px);
        padding-right: 24px;

        @media screen and (max-width: 800px) {
          width: calc(100% - 22.66px);
        }
      }

      p:first-letter {
        text-transform: capitalize;
      }

      img {
        filter: brightness(0) invert(1);
        margin-right: 10px;
        width: 16px;
        height: 16px;

        @media screen and (max-width: 800px) {
          width: 13.33px;
          height: 13.33px;
          margin-right: 9.33px;
        }
      }
    }

    .close {
      cursor: pointer;
      width: 16px;
      height: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 800px) {
        width: 24px;
        height: 24px;
      }

      img {
        width: 9.33px;
        height: 9.33px;

        @media screen and (max-width: 800px) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  @keyframes show-hide {
    0% {
      display: block;
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
}

.hide {
  display: none;
}